<template>
  <article>
    <!--바로가기 navi-->
    <div id="accNav">
      <router-link to="#content">본문 바로가기</router-link>
      <router-link to="#footer">푸터 바로가기</router-link>
    </div>

    <header :class="{ active: toggle }">
      <!--headArea-->
      <div class="headArea">
        <h1 class="logo">
          <router-link to="/main" @click.native="resetMenuState"
            ><div class="logoImg"></div>
            파주파킹</router-link
          >
        </h1>
        <button type="button" @click="topMenuBtn" class="moTopMenu">
          <span class="hidden">메뉴</span>
        </button>
      </div>
      <!--//headArea-->
      <!--contArea-->
      <div class="contArea">
        <div class="gnbsearchArea">
          <div class="bar">
            <label for="search" class="hidden">검색어 입력</label>
            <input
              type="text"
              id="search"
              title="검색어 입력"
              placeholder="검색어 입력"
              v-model="searchKeyword"
              @keyup.enter="search"
              @focus="focused"
              @blur="focusOut"
            />
            <button type="button" class="searchBtn" @click="search"><span class="hidden">검색</span></button>
          </div>
          <button type="button" class="siteMapBtn" @click="siteMapBtn" :class="{ active: stiemapbtn }">
            <span class="hidden">사이트맵</span>
          </button>
        </div>

        <!--utill-->
        <article class="utill">
          <!-- Logout -->
          <ul v-if="isLogin">
            <li class="logout">
              <span @click="actionLogout">로그아웃</span>
            </li>
            <li class="mypage">
              <router-link
                :to="
                  ['ROLE_USER', 'ROLE_ADMIN'].includes(this.$store.state.authorCode)
                    ? '/mypagegeneral'
                    : '/mypagebusiness'
                "
                title="마이페이지"
              >
                마이페이지
              </router-link>
            </li>
            <li class="welcome">
              <span>{{ empNm }}</span> 님 반갑습니다!
            </li>
          </ul>

          <!-- Login -->
          <ul v-else>
            <li class="login" @click="closeToggle">
              <router-link to="/login" title="로그인">로그인</router-link>
            </li>
            <li class="join" @click="closeToggle">
              <router-link to="/join" title="회원가입">회원가입</router-link>
            </li>
          </ul>
        </article>
        <!--//utill-->

        <!--gnb-->
        <nav class="gnb scroll">
          <ul class="depth01" v-for="(firDepth, firIndex) in firDepth" :key="firIndex">
            <li :class="{ show: firDepth.selectMenu === true }" @click="dropdown(firDepth)">
              <a class="tit" :title="firDepth.title">{{ firDepth.title }}</a>
              <transition
                name="accordion"
                v-on:before-enter="beforeEnter"
                v-on:enter="enter"
                v-on:before-leave="beforeLeave"
                v-on:leave="leave"
              >
                <ul class="depth02" v-if="firDepth.selectMenu === true">
                  <li
                    v-for="(secDepth, secIndex) in firDepth.menuList"
                    :key="secIndex"
                    :class="{ on: secDepth.selectMenu === true }"
                    @click="menuSelect(secDepth)"
                  >
                    <router-link
                      :to="secDepth.link"
                      :class="{
                        link: secDepth.link === '/qna',
                      }"
                      :title="secDepth.title"
                      >{{ secDepth.title }}</router-link
                    >
                  </li>
                </ul>
              </transition>
            </li>
          </ul>
        </nav>
        <!--//gnb-->
      </div>
      <!--//contArea-->
    </header>
    <!--sitemap-->
    <div class="sitemap" v-if="modalShow">
      <div class="siteHead">
        <h2>전체메뉴</h2>
        <button class="sitemapClose" @click="siteMapBtn"><span class="hidden">사이트맵 닫기</span></button>
      </div>
      <div class="siteCont">
        <ul class="menu">
          <li v-for="(firDepth, index) in firDepth" :key="index">
            <router-link class="depth01" :to="firDepth.link">{{ firDepth.title }}</router-link>
            <ul>
              <li v-for="(secDepth, index) in firDepth.menuList" :key="index">
                <router-link
                  class="depth02"
                  :class="{
                    link: secDepth.link === '/qna',
                  }"
                  :to="secDepth.link"
                  >{{ secDepth.title }}</router-link
                >
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!--sitemap-->
  </article>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';
import axios from '../../util/axios';

export default {
  data() {
    return {
      firDepth: [],
      modalShow: false,
      btnmenu: false,
      toggle: false,
      show: false,
      on: false,
      stiemapbtn: false,
      searchKeyword: '',
      check: false,

      firActive: {},
      secActive: {},
    };
  },

  created() {
    this.getGnbMenuList();
  },

  watch: {
    isLogin() {
      this.getGnbMenuList();
    },
    firActive(nv, ov) {
      ov.selectMenu = false;
    },
    secActive(nv, ov) {
      ov.selectMenu = false;
      nv.selectMenu = true;
    },
    $route(e) {
      let cate = e.meta.category;
      if (cate === undefined) {
        this.firDepth.map(item => {
          item.selectMenu = false;
          item.menuList.map(v => {
            v.selectMenu = false;
          });
        });
        return;
      }
      this.firDepth.map(item => {
        item.selectMenu = false;
        if (item.title === cate[0]) {
          item.selectMenu = true;
        }
        item.menuList.map(v => {
          v.selectMenu = false;
          if (v.title === cate[1]) {
            v.selectMenu = true;
          }
        });
      });
      this.topMenuBtn();
      this.modalShow = false;
      this.stiemapbtn = false;
      this.toggle = false;
    },
  },

  methods: {
    resetMenuState() {
      // 메뉴 상태 초기화
      this.toggle = false;
      this.stiemapbtn = false;
      this.modalShow = false;
      this.firActive = {};
      this.secActive = {};
      this.searchKeyword = '';
    },

    getGnbMenuList() {
      const copy = cloneDeep(this.menuList);

      const recursion = (menu = []) => {
        for (let i = 0; i < menu.length; i++) {
          if (!this.isLogin) {
            if (
              menu[i].link.includes('/mypage') ||
              menu[i].link.includes('/noticemanagement') ||
              menu[i].link.includes('/usermanagement')
            ) {
              menu[i] = '';
            }
          } else {
            if (this.idAdmin && menu[i].link.includes('/mypagebusiness')) {
              menu[i] = '';
            }

            if (menu[i].auth && menu[i].auth !== this.loginType) {
              if (this.loginType === 'ROLE_ADMIN') {
                continue;
              }
              menu[i] = '';
            }
          }

          if (menu[i].menuList) {
            recursion(menu[i].menuList);
          }
        }
      };

      recursion(copy);

      this.firDepth = copy.filter(v => {
        if (v.menuList) {
          v.menuList = v.menuList.filter(i => i !== '');
        }
        return v;
      });
    },
    // gnbMenu Open
    openGnbmenu(item) {
      item.showMenu = true;
    },
    // gnbMenu Close
    closeGnbmenu(item) {
      item.showMenu = false;
    },
    siteMapBtn() {
      this.modalShow = !this.modalShow;
      this.stiemapbtn = !this.stiemapbtn;
    },
    modalHide() {
      this.modalShow = false;
    },
    topMenuBtn() {
      this.toggle = !this.toggle;
    },
    dropdown(firDepth) {
      firDepth.selectMenu = true;
      this.firActive = firDepth;
    },
    menuSelect(secDepth) {
      this.secActive = secDepth;
    },

    closeResult() {
      this.btnmenu = false;
    },
    async actionLogout() {
      if (this.check) return;
      this.check = true;
      await axios('post', '/api/actionLogout.json');
      alert('로그아웃 하였습니다.');
      this.$store.dispatch('logout', undefined);
      this.$router.push('/login');
      this.check = false;
    },

    focused(e) {
      e.target.placeholder = '';
    },
    focusOut(e) {
      e.target.placeholder = '검색어 입력';
    },
    search() {
      if (search.value !== '') {
        this.$router.push({
          path: 'searchlist',
          query: { q: this.searchKeyword },
        });
      }
      this.toggle = false;
      this.searchKeyword = '';
    },

    beforeEnter: function (el) {
      el.style.height = '0';
    },
    enter: function (el) {
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave: function (el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave: function (el) {
      el.style.height = '0';
    },

    closeToggle() {
      this.toggle = false;
      this.modalShow = false;
      this.stiemapbtn = false;
    },
  },

  computed: {
    ...mapGetters({
      menuList: 'getMenuList',
    }),
    isLogin() {
      return this.$store.state.isLogin;
    },
    idAdmin() {
      return this.$store.state.authorCode === 'ROLE_ADMIN';
    },
    empNm() {
      return this.$store.state.empNm;
    },
    loginType() {
      return this.$store.state.authorCode ? this.$store.state.authorCode : '';
    },
  },
};
</script>
