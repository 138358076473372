import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import menuJson from './menuJson.json';
import { convertNullishToEmptyString } from '@/util/common';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],

  state: {
    isLogin: false,
    authorCode: undefined,
    empNo: undefined,
    empNm: undefined,
    empMobile: undefined,
    empCarNo: undefined,
    zipcode: undefined,
    addr: undefined,
    addrDetail: undefined,
    empDiv: undefined,
    companyName: undefined,
    marketYn: undefined,
    businessLogin: false,
    menuList: menuJson.menuList,
    prepayInfo: '',
    unpaidInfo: '',
    checkSessionExpired: false,
    storeId: undefined,
  },

  getters: {
    getMenuList: state => state.menuList,
  },

  mutations: {
    authorCode: function (state, payload) {
      state.authorCode = payload;
    },
    prepayInfo: function (state, payload) {
      state.prepayInfo = payload;
    },
    unpaidInfo: function (state, payload) {
      state.unpaidInfo = payload;
    },
    empNo: function (state, payload) {
      state.empNo = payload;
    },
    empNm: function (state, payload) {
      state.empNm = payload;
    },
    empMobile: function (state, payload) {
      state.empMobile = payload;
    },
    empCarNo: function (state, payload) {
      state.empCarNo = payload;
    },
    zipcode: function (state, payload) {
      state.zipcode = payload;
    },
    addr: function (state, payload) {
      state.addr = payload;
    },
    addrDetail: function (state, payload) {
      state.addrDetail = convertNullishToEmptyString(payload);
    },
    isLogin: function (state, payload) {
      state.isLogin = payload;
    },
    empDiv: function (state, payload) {
      state.empDiv = payload;
    },
    companyName: function (state, payload) {
      state.companyName = payload;
    },
    businessLogin: function (state, payload) {
      state.businessLogin = payload;
    },
    marketYn: function (state, payload) {
      state.marketYn = payload;
    },
    logout: function (state, payload) {
      state.authorCode = payload;
      state.empNo = payload;
      state.empNm = payload;
      state.empMobile = payload;
      state.empCarNo = payload;
      state.zipcode = payload;
      state.addr = payload;
      state.addrDetail = payload;
      state.empDiv = payload;
      state.companyName = payload;
      state.isLogin = false;
      state.businessLogin = false;
      state.marketYn = payload;
      state.menuList = menuJson.menuList;
    },
    checkSessionExpired: function (state, payload) {
      state.checkSessionExpired = payload;
    },
    storeId: function (state, payload) {
      state.storeId = payload;
    },
  },

  actions: {
    authorCode: function (context, payload) {
      context.commit('authorCode', payload);
    },
    prepayInfo: function (context, payload) {
      context.commit('prepayInfo', payload);
    },
    unpaidInfo: function (context, payload) {
      context.commit('unpaidInfo', payload);
    },
    empNo: function (context, payload) {
      context.commit('empNo', payload);
    },
    empNm: function (context, payload) {
      context.commit('empNm', payload);
    },
    empMobile: function (context, payload) {
      context.commit('empMobile', payload);
    },
    empCarNo: function (context, payload) {
      context.commit('empCarNo', payload);
    },
    zipcode: function (context, payload) {
      context.commit('zipcode', payload);
    },
    addr: function (context, payload) {
      context.commit('addr', payload);
    },
    addrDetail: function (context, payload) {
      context.commit('addrDetail', payload);
    },
    isLogin: function (context, payload) {
      context.commit('isLogin', payload);
    },
    logout: function (context, payload) {
      context.commit('logout', payload);
    },
    empDiv: function (context, payload) {
      context.commit('empDiv', payload);
    },
    companyName: function (context, payload) {
      context.commit('companyName', payload);
    },
    businessLogin: function (context, payload) {
      context.commit('businessLogin', payload);
    },
    marketYn: function (context, payload) {
      context.commit('marketYn', payload);
    },
    checkSessionExpired: function (context, payload) {
      context.commit('checkSessionExpired', payload);
    },
    storeId: function (context, payload) {
      context.commit('storeId', payload);
    },
  },
});
