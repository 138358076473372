<template>
  <ul class="breadCrumb">
    <li class="home" @click="$router.push('/main')">Home</li>
    <li>
      <p class="pageTxt">{{ firTitle }}</p>
      <ul class="depth">
        <li v-for="(item, index) in firMenuList" :key="index">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </li>
      </ul>
    </li>
    <li>
      <p class="pageTxt">{{ secTitle }}</p>
      <ul class="depth">
        <li v-for="(item, index) in secMenuList" :key="index">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </li>
      </ul>
    </li>
    <li v-if="trdTitle">
      <p class="pageTxt">{{ trdTitle }}</p>
      <ul class="depth">
        <li v-for="(item, index) in trdMenuList" :key="index" class="blod">
          <router-link :to="item.link">{{ item.title }}</router-link>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from 'vuex';
import { cloneDeep } from 'lodash';

export default {
  computed: {
    ...mapGetters({
      menuList: 'getMenuList',
    }),
    isLogin() {
      return this.$store.state.isLogin;
    },
    isBusiness() {
      return this.$store.state.empDiv === 'BSN'; // 사업자인지 확인
    },
    idAdmin() {
      return this.$store.state.authorCode === 'ROLE_ADMIN';
    },
    loginType() {
      return this.$store.state.authorCode || ''; // 사용자 권한 타입 (ROLE_USER, ROLE_BSN 등)
    },
  },

  created() {
    this.getTitle();
  },

  data() {
    return {
      firMenuList: [],
      secMenuList: [],
      trdMenuList: [],
      firTitle: '',
      secTitle: '',
      trdTitle: '',
    };
  },

  watch: {
    $route() {
      this.getTitle();
    },
  },

  methods: {
    getSnbMenuList() {
      const copy = cloneDeep(this.menuList);

      const recursion = (menu = []) => {
        return menu
          .filter(item => {
            if (this.idAdmin) {
              return !item.link.includes('/mypagebusiness');
            }

            if (!this.isLogin) {
              return !(item.auth === 'ROLE_ADMIN' || item.link.includes('mypage'));
            }

            if (this.isBusiness) {
              if (item.link.includes('/mypagebusiness')) {
                return true;
              }

              return item.auth === 'ROLE_STORE' || !item.auth;
            }

            if (this.loginType === 'ROLE_USER') {
              return item.auth === 'ROLE_USER' || !item.auth;
            }
            return item.auth === this.loginType || !item.auth;
          })
          .map(item => {
            if (item.menuList) {
              item.menuList = recursion(item.menuList);
            }
            return item;
          });
      };

      this.firMenuList = recursion(copy);
      this.secMenuList = [];
      this.trdMenuList = [];

      this.firMenuList.forEach(menu => {
        if (menu.title === this.firTitle && menu.menuList) {
          this.secMenuList = menu.menuList;

          this.secMenuList.forEach(subMenu => {
            if (subMenu.title === this.secTitle && subMenu.menuList) {
              this.trdMenuList = subMenu.menuList;
            }
          });
        }
      });
    },

    getTitle() {
      this.firTitle = this.$route.meta.category?.[0] || '';
      this.secTitle = this.$route.meta.category?.[1] || '';
      this.trdTitle = this.$route.meta.category?.[2] || '';

      this.getSnbMenuList();
    },
  },
};
</script>
